// const API_ENDPOINT = 'http://localhost:8080/v1'; // LOCALE
// const API_ENDPOINT = 'https://staging.api.biopeace.katomi.co/v1'; // STAGING
const API_ENDPOINT = 'https://api-biopeace.eshmoun.com.tn/v1'; // PROD

const API_AUTH = `${API_ENDPOINT}/auth`;
const API_PATIENTS = `${API_ENDPOINT}/patients`;
const API_CALLS = `${API_ENDPOINT}/calls`;
const API_VISITS = `${API_ENDPOINT}/visits`;
const API_QUERY = `${API_ENDPOINT}/queries`;
const API_ADVERSE = `${API_ENDPOINT}/adverseEvents`;
const API_CSV = `${API_ENDPOINT}/csv`;
const API_DOC = `${API_ENDPOINT}/documents`;


const API_REQ_HEADER = {
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
};


export {
    API_AUTH,
    API_PATIENTS,
    API_VISITS,
    API_REQ_HEADER,
    API_QUERY,
    API_ADVERSE,
    API_CSV,
    API_DOC,
    API_CALLS
};
